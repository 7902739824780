import React from 'react';

const SideBlock = (props) => {

    const { topText, logo, bottomText, value } = props;


    return (
        <div className="apyblock ">
            <div className="apyblock-header">
                {
                    typeof logo !== 'undefined' ?
                        <img height={15} src={logo} alt={topText} />
                        : null
                }
                {topText}
            </div>
            <div className="apyblock-box mono-text">
                <div className="big-text">
                    {value}
                </div>
                <div className="apyblock-box-footer">
                    {bottomText}
                </div>
            </div>
        </div>
    );
};

export default SideBlock;