import React from 'react';
// import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
// import { Container as SUIContainer } from 'semantic-ui-react';

const Wrapper = ({ children, id }) => {
    return (
        <div id={id} >
            {children}
        </div>
    );
};

Wrapper.propTypes = {
    height: PropTypes.string
};

Wrapper.defaultProps = {
    height: 'auto'
};

export default Wrapper;
