import React, { useState } from 'react';
import Button from '../components/shared/Button';
import pwError from '../components/alerts/pwError';
import SortableTh from './SortableTh';
import { PactContext } from '../contexts/PactContext';
import { formatUnixTimestamp } from '../utils/helpers';
import { orderBy } from 'lodash-es';

const TableComponent = (props) => {
    const pact = React.useContext(PactContext);

    const { 
        coinsArray,
        unlockLocal,
        unlockWallet,
        localRes,
        signing,
        unlockXwallet,
    } = props;

    const [, setLoading ] = useState(false);
    const [, setShowTxModal] = useState(false);
    const [sorting, setSorting] = useState({column: null, direction: null});

    const getUnlockType = (id, withdrawn, amount, rewards) => {
        if (withdrawn) {
            return (
                <Button
                    color="#fde87d"
                    background="#4c4837"
                    disabled
                    buttonStyle={{ minWidth: 150 }}
                >
                    Completed
                </Button>
            );
        }
        return (
            <Button
                color="black"
                background="#7dfd89"
                buttonStyle={{ minWidth: 150 }}
                onClick={async () => {
                    setLoading(true);
                    if (pact.xAccount?.account == pact.account.account) {
                        unlockXwallet(id, amount, rewards);
                        setLoading(false);
                    } else {
                        if (signing.method !== 'sign') {
                            const res = await unlockLocal(id, amount, rewards);
                            if (res === -1) {
                                setLoading(false);
                                if (localRes) {
                                    pwError();
                                }
                            } else {
                                setShowTxModal(true);
                                setLoading(false);
                            }
                        } else {
                            unlockWallet(id, amount, rewards);
                            setLoading(false);
                        }
                    }
                }}
            >
                Withdraw
            </Button>
        );
    };

    return(
        <table className="ui inverted striped selectable sortable table">
            <thead>
                <tr>
                    <SortableTh
                        column="id.int"
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        Id
                    </SortableTh>
                    <SortableTh
                        column="timestamp.timep"
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        Created
                    </SortableTh>
                    <SortableTh 
                        column="amount"                        
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        Amount
                    </SortableTh>
                    <SortableTh
                        column="rewards"
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        Rewards
                    </SortableTh>
                    <SortableTh 
                        column="unlockat.timep"
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        Unlock Date
                    </SortableTh>
                    <th>
                        Unlock
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    orderBy(coinsArray, [sorting.column], [sorting.direction]).map((coinInfo) => {
                        const { 
                            id: { int: id },
                            timestamp: { timep: timestamp },
                            amount,
                            rewards,
                            unlockat: { timep: unlockat },
                            withdrawn
                        } = coinInfo;
                            
                        const amount_transformed = typeof amount === 'number' ? amount : amount.decimal;
                        const rewards_transformed = typeof rewards === 'number' ? rewards : rewards.decimal;

                        return(
                            <tr key={id}>
                                <td>{id}</td>
                                <td>{formatUnixTimestamp(timestamp)}</td>
                                <td>{amount_transformed.toLocaleString('en')}</td>
                                <td>{rewards_transformed.toLocaleString('en')}</td>
                                <td>{formatUnixTimestamp(unlockat)}</td>
                                <td>{getUnlockType(id, withdrawn, amount, rewards)}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
};

export default TableComponent;
