import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Dashboard from '../containers/Dashboard';
import Activelockslist from '../containers/Activelockslist';
import {
    ROUTE_INDEX,
    ROUTE_LOCK,
    ROUTE_STATS
} from './routes';

export default () => {
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path={ROUTE_INDEX} component={Dashboard} />
                    <Route exact path={ROUTE_LOCK} component={Dashboard} />
                    <Route exact path={ROUTE_STATS} component={Activelockslist} />
                </Switch>
            </Layout>
        </Router>
    );
};
