import moment from 'moment';

export const roundNumbers = (value, decPlaces) => {
    return Math.round((value+ Number.EPSILON)*Math.pow(10, decPlaces))/Math.pow(10, decPlaces);
};

export const formatUnixTimestamp = (timestamp, format = 'L HH:mm:ss') => {
    return moment(timestamp).format(format);
};

export const getPrecision = (a) => {
    if (!isFinite(a) || isNaN(a)) {
        return 0;
    }
    let e = 1;
    let p = 0;
    while (Math.round(a * e) / e !== a) { 
        e *= 10; 
        p++;
    }
    return p;
};