import React, {
    useState,
    createContext,
    useEffect,
    useContext,
} from 'react';
import Pact from 'pact-lang-api';
//import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { NotificationContext, STATUSES } from './NotificationContext';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import pwPrompt from '../components/alerts/pwPrompt';
import walletError from '../components/alerts/walletError';
import walletSigError from '../components/alerts/walletSigError';
import walletLoading from '../components/alerts/walletLoading';
import { reduceBalance } from '../utils/reduceBalance';
const fetch = require('node-fetch');

export const PactContext = createContext();
const savedAcct = localStorage.getItem('acct');
const savedPrivKey = localStorage.getItem('pk');
//const savedNetwork = localStorage.getItem('network');
const savedSlippage = localStorage.getItem('slippage');
const savedSigning = localStorage.getItem('signing');
const savedTtl = localStorage.getItem('ttl');
const chainId = '3';
const babenaBank = 'babena-bank';
const lpAccount = 'c14BqpLAgABrIoceLq4cd6XuxEgIbC0L6qxmihfVRqw';
const PRECISION = 12;
const NETWORKID = 'mainnet01';
const FEE = 0.003;
//const network = `http://kadena.sexy:1848/chainweb/0.0/mainnet01/chain/${chainId}/pact`;
const network = `https://api.chainweb.com/chainweb/0.0/mainnet01/chain/${chainId}/pact`;

const creationTime = () => Math.round(new Date().getTime() / 1000) - 10;
const GAS_PRICE = 0.00000001;

export const PactProvider = (props) => {
    const notificationContext = useContext(NotificationContext);
    const [verifiedAccount, setVerifiedAccount] = useState({ account: null, guard: null, balance: 0 });
    const [account, setAccount] = useState({ account: null, guard: null, balance: 0 });
    // const [tokenAccount, setTokenAccount] = useState({
    //     account: null,
    //     guard: null,
    //     balance: 0
    // });
    const [privKey, setPrivKey] = useState(savedPrivKey ? savedPrivKey : '');
    const keyPair = privKey
        ? Pact.crypto.restoreKeyPairFromSecretKey(privKey)
        : '';
    const [tokenFromAccount, setTokenFromAccount] = useState({
        account: null,
        guard: null,
        balance: 0
    });
    const [tokenToAccount, setTokenToAccount] = useState({
        account: null,
        guard: null,
        balance: 0
    });
    //const [precision, setPrecision] = useState(false);
    const [pairAccount, setPairAccount] = useState('');
    const [pairReserve, setPairReserve] = useState('');
    const [pair, setPair] = useState('');
    const [ratio, setRatio] = useState(NaN);
    const [pairAccountBalance, setPairAccountBalance] = useState(null);
    const [supplied, setSupplied] = useState(false);
    const [slippage, setSlippage] = useState(
        savedSlippage ? savedSlippage : 0.05
    );
    //const [liquidityProviderFee, setLiquidityProviderFee] = useState(0.003);
    const [cmd, setCmd] = useState(null);
    const [localRes, setLocalRes] = useState(null);
    const [polling, setPolling] = useState(false);
    const [availableKda, setAvailableKda] = useState(0);
    const [sixtyDaysKda, setSixtyDaysKda] = useState(0);
    const [wholeYearKda, setWholeYearKda] = useState(0);
    const [availableBabe, setAvailableBabe] = useState(0);
    const [babeBalance, setBabeBalance] = useState(0);
    const [sixtyDaysBabe, setSixtyDaysBabe] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [emergencyKda, setEmergencyKda] = useState(0);
    const [emergencyBabe, setEmergencyBabe] = useState(0);
    const [circulatingSupply, setCirculatingSupply] = useState(0);
    const [wholeYearBabe, setWholeYearBabe] = useState(0);
    const [multiplierKda, setMultiplierKda] = useState(0);
    const [multiplierBabe, setMultiplierBabe] = useState(0);
    const [viewMyLocksKda, setviewMyLocksKda] = useState([]);
    const [viewMyLocksBabe, setviewMyLocksBabe] = useState([]);
    const [totaLockedKda, setTotaLockedKda] = useState(0);
    const [totaLockedBabe, setTotaLockedBabe] = useState(0);
    const [getallkda, setgetallkda] = useState([]);
    const [getallbabe, setgetallbabe] = useState([]);
    const [poolBalance, setPoolBalance] = useState(['N/A', 'N/A']);
    const [sendRes, setSendRes] = useState(null);
    const [signing, setSigning] = useState({ method: 'none', key: '' });
    const [sigView, setSigView] = useState(false);
    const [pw, setPw] = useState('');
    //const [pwStatus, setPwStatus] = useState('');
    const [walletSuccess, setWalletSuccess] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [ttl, setTtl] = useState(savedTtl ? savedTtl : 600);
    //const [balances, setBalances] = useState(false);
    //TO FIX, not working when multiple toasts are there
    const toastId = React.useRef(null);
    // const [toastIds, setToastIds] = useState({})
    useEffect(() => {
        if (account.account) {
            setRegistered(true);
        }
    }, [registered]);

    useEffect(() => {
        pairReserve
            ? setRatio(pairReserve['token0'] / pairReserve['token1'])
            : setRatio(NaN);
    }, [pairReserve]);

    useEffect(() => {
        const store = async () =>
            localStorage.setItem('signing', JSON.stringify(signing));
        store();
    }, [signing]);

    useEffect(( ) => {
        if(savedAcct) {
            setAccount(JSON.parse(savedAcct));
            setVerifiedAccount(JSON.parse(savedAcct));
        }
    }, [savedAcct]);

    useEffect(() => {
        if(savedSigning) {
            setSigning(JSON.parse(savedSigning));
        }
    }, [savedSigning]);

    useEffect(() => {
        getAvailableKda();
    }, [availableKda]);

    useEffect(() => {
        getSixtyDaysKda();
    }, [sixtyDaysKda]);

    useEffect(() => {
        getWholeYearKda();
    }, [wholeYearKda]);

    useEffect(() => {
        getAvailableBabe();
    }, [availableBabe]);

    useEffect(() => {
        getBabeBalance();
    }, [account.account]);

    useEffect(() => {
        getSixtyDaysBabe();
    }, [sixtyDaysBabe]);

    useEffect(() => {
        getTotalSupply();
    }, [totalSupply]);

    useEffect(() => {
        getCirculatingSupply();
    }, [circulatingSupply]);

    useEffect(() => {
        getEmergencyKda();
    }, [emergencyKda]);

    useEffect(() => {
        getEmergencyBabe();
    }, [emergencyBabe]);

    useEffect(() => {
        getWholeYearBabe();
    }, [wholeYearBabe]);

    useEffect(() => {
        getMultiplierKda();
    }, [multiplierKda]);

    useEffect(() => {
        getMultiplierBabe();
    }, [multiplierBabe]);

    useEffect(() => {
        getTotaLockedKda();
    }, [totaLockedKda]);

    useEffect(() => {
        getTotaLockedBabe();
    }, [totaLockedBabe]);
    // useEffect(() => {
    //     if(account.account !== null) {
    //         console.log('here in the getViewMyKDA locks');
    //         getviewMyLocksKda();
    //         getviewMyLocksBabe();
    //     }
    // }, [account]);

    // useInterval(() => {
    //     console.log('polling');
    //     getviewMyLocksKda();
    //     getviewMyLocksBabe();
    // }, 20000);

    // console.log(account);

    // useEffect(() => {

    // }, []);

    useEffect(() => {
        viewallkda();
    }, []);

    useEffect(() => {
        viewallbabe();
    }, []);


    const pollingNotif = (reqKey) => {
        return (toastId.current = notificationContext.showNotification({
            title: 'Transaction Pending',
            message: reqKey,
            type: STATUSES.INFO,
            autoClose: 92000,
            hideProgressBar: false
        }));
    };

    const getCorrectBalance = (balance) => {
        const balanceClean = !isNaN(balance) ? balance : balance.decimal;
        return balanceClean;
    };

    const storeSlippage = async (slippage) => {
        await setSlippage(slippage);
        await localStorage.setItem('slippage', slippage);
    };

    const storeTtl = async (ttl) => {
        await setTtl(slippage);
        await localStorage.setItem('ttl', ttl);
    };

    const updateAccount = async (account) => {
        await localStorage.setItem('acct', JSON.stringify(account));
        signingWallet();
        setAccount({account: account.account, balance: getCorrectBalance(account.balance)});
    };

    const updateAccountInformation = async (accountName) => {
        getBabeBalance();
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(coin.details ${JSON.stringify(accountName)})`,
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                await localStorage.setItem('acct', JSON.stringify(data.result.data));
                setAccount({
                    ...data.result.data,
                    balance: getCorrectBalance(data.result.data.balance)
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAccountVerification = async (accountName) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(coin.details ${JSON.stringify(accountName)})`,
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                // await localStorage.setItem("acct", JSON.stringify(data.result.data));
                setVerifiedAccount({
                    ...data.result.data,
                    //balance: getCorrectBalance(data.result.data.balance)
                });
                // await localStorage.setItem("acct", JSON.stringify(data.result.data));
            } else {
                setVerifiedAccount({ account: null, guard: null, balance: 0 });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getTokenAccount = async (token, account, first) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(${token}.details ${JSON.stringify(account)})`,
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        0.01,
                        100000000,
                        28800,
                        creationTime()
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                // setTokenAccount({...data.result.data, balance: getCorrectBalance(data.result.data.balance)});
                first
                    ? setTokenFromAccount(data.result.data)
                    : setTokenToAccount(data.result.data);
                return data.result.data;
            } else if (data.result.status === 'failure') {
                first
                    ? setTokenFromAccount({ account: null, guard: null, balance: 0 })
                    : setTokenToAccount({ account: null, guard: null, balance: 0 });
                return { account: null, guard: null, balance: 0 };
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getSixtyDaysKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcurrent60dayskda)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal) {setSixtyDaysKda(data.result.data.decimal);}
                else {setSixtyDaysKda(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getWholeYearKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcurrent365dayskda)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal) {setWholeYearKda(data.result.data.decimal);}
                else {setWholeYearKda(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getMultiplierKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getmultiplierkda)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setMultiplierKda(data.result.data.decimal);}
                else {setMultiplierKda(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAvailableKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcumulativekda)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal) {setAvailableKda(data.result.data.decimal);}
                else {setAvailableKda(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getSixtyDaysBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcurrent120daysbabe)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setSixtyDaysBabe(data.result.data.decimal);}
                else {setSixtyDaysBabe(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getTotalSupply = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.gettotalsupply)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setTotalSupply(data.result.data.decimal);}
                else {setTotalSupply(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getEmergencyKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getemergencykda)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setEmergencyKda(data.result.data.decimal);}
                else {setEmergencyKda(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getEmergencyBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getemergencybabe)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setEmergencyBabe(data.result.data.decimal);}
                else {setEmergencyBabe(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getCirculatingSupply = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcirculatingsupply)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setCirculatingSupply(data.result.data.decimal);}
                else {setCirculatingSupply(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getWholeYearBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcurrent1095daysbabe)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setWholeYearBabe(data.result.data.decimal);}
                else {setWholeYearBabe(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getMultiplierBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getmultiplierbabe)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setMultiplierBabe(data.result.data.decimal);}
                else {setMultiplierBabe(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAvailableBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getcumulativebabe)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal)
                {setAvailableBabe(data.result.data.decimal);}
                else {setAvailableBabe(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getBabeBalance = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.babena.get-balance ${JSON.stringify(
                        account.account
                    )})`,
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal) {setBabeBalance(data.result.data.decimal);}
                else {setBabeBalance(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getviewMyLocksKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.babena.getmineskda ${JSON.stringify(
                        account.account
                    )})`,
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        0.01,
                        100000000,
                        28800,
                        creationTime()
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                setviewMyLocksKda(data.result.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getviewMyLocksBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.babena.getminesbabe ${JSON.stringify(
                        account.account
                    )})`,
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        0.01,
                        100000000,
                        28800,
                        creationTime()
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                // console.log(data.result);
                // console.log(data.result.data);
                setviewMyLocksBabe(data.result.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const viewallkda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getallkda)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        0.01,
                        100000000,
                        28800,
                        creationTime()
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                // console.log(data.result);
                // console.log(data.result.data);
                setgetallkda(data.result.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const viewallbabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.getallbabe)',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        0.01,
                        100000000,
                        28800,
                        creationTime()
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                // console.log(data.result);
                // console.log(data.result.data);
                setgetallbabe(data.result.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const createTokenPairLocal = async (
        token0,
        token1,
        amountDesired0,
        amountDesired1
    ) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.exchange.create-pair
              ${token0.code}
              ${token1.code}
              ""
            )`,
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        15000,
                        creationTime(),
                        28800
                    ),
                    networkId: NETWORKID
                },
                network
            );
            let pair = data.result.data.account;
            try {
                let cmd = {
                    pactCode: `
            (free.exchange.create-pair
                ${token0.code}
                ${token1.code}
                ""
            )
            (free.exchange.add-liquidity
                ${token0.code}
                ${token1.code}
                (read-decimal 'amountDesired0)
                (read-decimal 'amountDesired1)
                (read-decimal 'amountMinimum0)
                (read-decimal 'amountMinimum1)
                ${JSON.stringify(account.account)}
                ${JSON.stringify(account.account)}
                (read-keyset 'user-ks)
              )`,
                    keyPairs: {
                        ...keyPair,
                        clist: [
                            {
                                name: `${token0.code}.TRANSFER`,
                                args: [account.account, pair, Number(amountDesired0)]
                            },
                            {
                                name: `${token1.code}.TRANSFER`,
                                args: [account.account, pair, Number(amountDesired1)]
                            },
                            { name: 'coin.GAS', args: [] }
                        ]
                    },

                    meta: Pact.lang.mkMeta(
                        account.account,
                        chainId,
                        GAS_PRICE,
                        15000,
                        creationTime(),
                        600
                    ),
                    networkId: NETWORKID
                };
                let data = await Pact.fetch.local(cmd, network);
                setCmd(cmd);
                setLocalRes(data);
                return data;
            } catch (e) {
                setLocalRes({});
                console.log(e);
                return -1;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const addLiquidityLocal = async (
        token0,
        token1,
        amountDesired0,
        amountDesired1
    ) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return;
            }
            let pair = await getPairAccount(token0.code, token1.code);
            let cmd = {
                pactCode: `(free.exchange.add-liquidity
              ${token0.code}
              ${token1.code}
              (read-decimal 'amountDesired0)
              (read-decimal 'amountDesired1)
              (read-decimal 'amountMinimum0)
              (read-decimal 'amountMinimum1)
              ${JSON.stringify(account.account)}
              ${JSON.stringify(account.account)}
              (read-keyset 'user-ks)
            )`,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
                    clist: [
                        {
                            name: `${token0.code}.TRANSFER`,
                            args: [account.account, pair, Number(amountDesired0)]
                        },
                        {
                            name: `${token1.code}.TRANSFER`,
                            args: [account.account, pair, Number(amountDesired1)]
                        },
                        { name: 'coin.GAS', args: [] }
                    ]
                },

                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    13000,
                    creationTime(),
                    600
                ),
                networkId: NETWORKID
            };
            let data = await Pact.fetch.local(cmd, network);
            setCmd(cmd);
            setLocalRes(data);
            return data;
        } catch (e) {
            setLocalRes({});
            console.log(e);
            return -1;
        }
    };

    // const addLiquidityWallet = async (
    //     token0,
    //     token1,
    //     amountDesired0,
    //     amountDesired1
    // ) => {
    //     try {
    //         let pair = await getPairAccount(token0.code, token1.code);
    //         const signCmd = {
    //             pactCode: `(free.exchange.add-liquidity
    //         ${token0.code}
    //         ${token1.code}
    //         (read-decimal 'amountDesired0)
    //         (read-decimal 'amountDesired1)
    //         (read-decimal 'amountMinimum0)
    //         (read-decimal 'amountMinimum1)
    //         ${JSON.stringify(account.account)}
    //         ${JSON.stringify(account.account)}
    //         (read-keyset 'user-ks)
    //       )`,
    //             caps: [
    //                 Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
    //                 Pact.lang.mkCap(
    //                     'transfer capability',
    //                     'Transfer Token to Pool',
    //                     `${token0.code}.TRANSFER`,
    //                     [account.account, pair, Number(amountDesired0)]
    //                 ),
    //                 Pact.lang.mkCap(
    //                     'transfer capability',
    //                     'Transfer Token to Pool',
    //                     `${token1.code}.TRANSFER`,
    //                     [account.account, pair, Number(amountDesired1)]
    //                 )
    //             ],
    //             sender: account.account,
    //             gasLimit: 3000,
    //             gasPrice: GAS_PRICE,
    //             chainId: chainId,
    //             ttl: 600,

    //             signingPubKey: account.guard.keys[0],
    //             networkId: NETWORKID
    //         };
    //         //alert to sign tx
    //         walletLoading();
    //         const cmd = await Pact.wallet.sign(signCmd);
    //         //close alert programmatically
    //         swal.close();
    //         setWalletSuccess(true);
    //         //set signedtx
    //         setCmd(cmd);
    //         let data = await fetch(`${network}/api/v1/local`, mkReq(cmd));
    //         data = await parseRes(data);
    //         setLocalRes(data);
    //         return data;
    //     } catch (e) {
    //         //wallet error alert
    //         if (e.message.includes('Failed to fetch')) {walletError();}
    //         else {walletSigError();}
    //         console.log(e);
    //     }
    // };

    const removeLiquidityLocal = async (token0, token1, liquidity) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return;
            }
            let pairKey = await getPairKey(token0, token1);
            let pair = await getPairAccount(token0, token1);
            let cmd = {
                pactCode: `(free.exchange.remove-liquidity
              ${token0}
              ${token1}
              (read-decimal 'liquidity)
              0.0
              0.0
              ${JSON.stringify(account.account)}
              ${JSON.stringify(account.account)}
              (read-keyset 'user-ks)
            )`,
                networkId: NETWORKID,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
                    clist: [
                        {
                            name: 'free.tokens.TRANSFER',
                            args: [pairKey, account.account, pair, Number(liquidity)]
                        },
                        { name: 'coin.GAS', args: [] }
                    ]
                },
                envData: {
                    'user-ks': account.guard,
                    liquidity: reduceBalance(liquidity, PRECISION)
                },
                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    13000,
                    creationTime(),
                    600
                )
            };
            setCmd(cmd);
            let data = await Pact.fetch.local(cmd, network);
            setLocalRes(data);
            return data;
        } catch (e) {
            setLocalRes({});
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            return -1;
        }
    };

    const removeLiquidityWallet = async (token0, token1, liquidity) => {
        try {
            let pairKey = await getPairKey(token0, token1);
            let pair = await getPairAccount(token0, token1);
            const signCmd = {
                pactCode: `(free.exchange.remove-liquidity
            ${token0}
            ${token1}
            (read-decimal 'liquidity)
            0.0
            0.0
            ${JSON.stringify(account.account)}
            ${JSON.stringify(account.account)}
            (read-keyset 'user-ks)
          )`,
                caps: [
                    Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                    Pact.lang.mkCap(
                        'transfer capability',
                        'Transfer Token to Pool',
                        'free.tokens.TRANSFER',
                        [pairKey, account.account, pair, Number(liquidity)]
                    )
                ],
                sender: account.account,
                gasLimit: 13000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    'user-ks': account.guard,
                    liquidity: reduceBalance(liquidity, PRECISION)
                },
                signingPubKey: account.guard.keys[0],
                networkId: NETWORKID
            };
            //alert to sign tx
            walletLoading();
            const cmd = await Pact.wallet.sign(signCmd);
            //close alert programmatically
            swal.close();
            setWalletSuccess(true);
            setCmd(cmd);
            let data = await fetch(`${network}/api/v1/local`, mkReq(cmd));
            data = await parseRes(data);
            setLocalRes(data);
            return data;
        } catch (e) {
            //wallet error alert
            setLocalRes({});
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            console.log(e);
        }
    };

    const getPairAccount = async (token0, token1) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(at 'account (free.exchange.get-pair ${token0} ${token1}))`,
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                setPairAccount(data.result.data);
                return data.result.data;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getPair = async (token0, token1) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.exchange.get-pair ${token0} ${token1})`,
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                setPair(data.result.data);
                return data.result.data;
            }
            return null;

        } catch (e) {
            console.log(e);
        }
    };

    const getPairKey = async (token0, token1) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.exchange.get-pair-key ${token0} ${token1})`,
                    meta: Pact.lang.mkMeta(
                        account.account,
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                return data.result.data;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getPairAccountBalance = async (token0, token1, account) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `(free.tokens.get-balance (free.exchange.get-pair-key ${token0} ${token1}) ${JSON.stringify(
                        account
                    )})`,
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                setPairAccountBalance(data.result.data);
            }
        } catch (e) {
            console.log(e);
        }
    };





    const getReserves = async (token0, token1) => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `
          (use free.exchange)
          (let*
            (
              (p (get-pair ${token0} ${token1}))
              (reserveA (reserve-for p ${token0}))
              (reserveB (reserve-for p ${token1}))
            )[reserveA reserveB])
           `,
                    meta: Pact.lang.mkMeta(
                        'account',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            if (data.result.status === 'success') {
                await setPairReserve({
                    token0: data.result.data[0].decimal
                        ? data.result.data[0].decimal
                        : data.result.data[0],
                    token1: data.result.data[1].decimal
                        ? data.result.data[1].decimal
                        : data.result.data[1]
                });
            } else {
                await setPairReserve({});
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getPooledAmount = async (pairKey, token0, token1, account) => {
        //let pair = await getPairAccount(token0, token1);
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: `
          (use free.exchange)
          (let*
            (
              (p (get-pair ${token0} ${token1}))
              (reserveA (reserve-for p ${token0}))
              (reserveB (reserve-for p ${token1}))
              (totalBal (free.tokens.total-supply (free.exchange.get-pair-key ${token0} ${token1})))
              (acctBal (free.tokens.get-balance (free.exchange.get-pair-key ${token0} ${token1}) ${JSON.stringify(
    account
)}))
            )[(* reserveA (/ acctBal totalBal))(* reserveB (/ acctBal totalBal))])
           `,
                    meta: Pact.lang.mkMeta(
                        '',
                        chainId,
                        GAS_PRICE,
                        13000,
                        creationTime(),
                        600
                    )
                },
                network
            );
            let balance0 = data.result.data[0].decimal
                ? data.result.data[0].decimal
                : data.result.data[0];
            let balance1 = data.result.data[1].decimal
                ? data.result.data[1].decimal
                : data.result.data[1];
            setPoolBalance([balance0, balance1]);
        } catch (e) {
            console.log(e);
        }
    };

    // const tokens = async (token0, token1, account) => {
    //     try {
    //         let data = await Pact.fetch.local(
    //             {
    //                 pactCode: `
    //       (free.tokens.get-tokens)
    //        `,
    //                 meta: Pact.lang.mkMeta(
    //                     '',
    //                     chainId,
    //                     GAS_PRICE,
    //                     3000,
    //                     creationTime(),
    //                     600
    //                 )
    //             },
    //             network
    //         );
    //         if (data.result.status === 'success') {
    //             return data.result.data;
    //         }
    //         await setPairReserve(null);
    //         console.log('Failed');

    //     } catch (e) {
    //         console.log(e);
    //     }
    // };






    const swapSend = async () => {
        setPolling(true);
        try {
            let data;
            if (cmd.pactCode) {
                data = await Pact.fetch.send(cmd, network);
            } else {
                data = await Pact.wallet.sendSigned(cmd, network);
            }
            pollingNotif(data.requestKeys[0]);
            await listen(data.requestKeys[0]);
            setPolling(false);
        } catch (e) {
            setPolling(false);
            console.log(e);
        }
    };

    const wait = async (timeout) => {
        return new Promise((resolve) => {
            setTimeout(resolve, timeout);
        });
    };

    const listen = async (reqKey) => {
    //check kadena tx status every 10 seconds until we get a response (success or fail)
        let time = 240;
        let pollRes;
        while (time > 0) {
            await wait(5000);
            pollRes = await Pact.fetch.poll({ requestKeys: [reqKey] }, network);
            if (Object.keys(pollRes).length === 0) {
                console.log('no return poll');
                console.log(pollRes);
                time = time - 5;
            } else {
                console.log(pollRes);
                time = 0;
            }
        }
        setSendRes(pollRes);
        // console.log(reqKey);
        // console.log(pollRes);
        // console.log(pollRes[reqKey]);
        // console.log(pollRes[reqKey].result);
        if (pollRes[reqKey].result.status === 'success') {
            notificationContext.showNotification({
                title: 'Transaction Success!',
                message: 'Check it out in the block explorer',
                type: STATUSES.SUCCESS,
                onClose: async () => {
                    await toast.dismiss(toastId);
                    await window.open(
                        `https://explorer.chainweb.com/mainnet/tx/${reqKey}`,
                        '_blank',
                        'noopener,noreferrer'
                    );
                },
                onOpen: async () => {
                    await toast.dismiss(toastId.current);
                }
            });
        } else {
            notificationContext.showNotification({
                title: 'Transaction Failure!',
                message: 'Check it out in the block explorer',
                type: STATUSES.ERROR,
                onClose: async () => {
                    await toast.dismiss(toastId);
                    await window.open(
                        `https://explorer.chainweb.com/mainnet/tx/${reqKey}`,
                        '_blank',
                        'noopener,noreferrer'
                    );
                    window.location.reload();
                },
                onOpen: async () => {
                    await toast.dismiss(toastId.current);
                }
            });
        }
    };

    const getRatio = (toToken, fromToken) => {
        if (toToken === fromToken) {return 1;}
        return pairReserve['token1'] / pairReserve['token0'];
    };

    const getRatio1 = (toToken, fromToken) => {
        if (toToken === fromToken) {return 1;}
        return pairReserve['token0'] / pairReserve['token1'];
    };

    const share = (amount) => {
        return Number(amount) / (Number(pairReserve['token0']) + Number(amount));
    };

    const clearSendRes = () => {
    setVerifiedAccount(account.account);
        setSendRes(null);
    };

    const storePrivKey = async (pk) => {
        setSigning({ method: 'pk', key: pk });
        await setPrivKey(pk);
        await localStorage.setItem('pk', pk);
    };

    const setSigningMethod = async (meth) => {
        await setSigning({ ...signing, method: meth });
    };

    const signingWallet = () => {
        setSigning({ method: 'sign', key: '' });
    };

    const decryptKey = async (pw) => {
        //const singing = await localStorage.getItem('signing');
        const encrypted = signing.key;
        const decryptedObj = CryptoJS.RC4Drop.decrypt(encrypted, pw);
        if (decryptedObj.sigBytes < 0) {return null;}
        return decryptedObj.toString(CryptoJS.enc.Utf8);
    };

    const encryptKey = async (pk, pw) => {
        const encrypted = CryptoJS.RC4Drop.encrypt(pk, pw);
        setSigning({ method: 'pk+pw', key: encrypted });
    };

    const logout = () => {
        localStorage.removeItem('acct', null);
        localStorage.removeItem('signing', null);
        window.location.reload();
    };

    const hasWallet = () => {
        if (signing.method === 'sign') {
            return true;
        }
        if (signing.method === 'pk') {return true;}
        if (signing.method === 'pk+pw') {return true;}
        return false;
    };

    //------------------------------------------------------------------------------------------------------------------------
    //                  START KPENNY FUNCTIONS ONLY
    //------------------------------------------------------------------------------------------------------------------------

    const lockKdaLocal = async (amtKda, dayKda) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return -1;
            }
            const ct = creationTime();
            const pactCode = `(free.babena.lock-kda ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks") (read-integer 'day) (read-decimal 'amt))`;
            const cmd = {
                pactCode: pactCode,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
                    clist: [
                        { name: 'coin.GAS', args: [] },
                        {
                            name: 'coin.TRANSFER',
                            args: [account.account, babenaBank, reduceBalance(amtKda, 12)]
                        }
                    ]
                },
                envData: {
                    amt: amtKda,
                    day: dayKda,
                    'user-ks': account.guard
                },
                networkId: NETWORKID,
                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    8000,
                    ct,
                    600
                )
            };
            setCmd(cmd);
            let data = await Pact.fetch.local(cmd, network);
            setLocalRes(data);
            return data;
        } catch (e) {
            console.log(e);
            setLocalRes({});
            return -1;
        }
    };

    const lockKdaWallet = async (amtKda, dayKda) => {
        try {
            const pactCode = `(free.babena.lock-kda ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks") (read-integer 'day) (read-decimal 'amt))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [
                    Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                    Pact.lang.mkCap(
                        'transfer capability',
                        'transfer token in',
                        'coin.TRANSFER',
                        [account.account, babenaBank, reduceBalance(amtKda, 12)]
                    )
                ],
                sender: account.account,
                gasLimit: 8000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    amt: amtKda,
                    day: dayKda,
                    'user-ks': account.guard
                },
                signingPubKey: account.guard.keys[0],
                networkId: NETWORKID
            };
            //alert to sign tx
            walletLoading();
            console.log(signCmd);
            const cmd = await Pact.wallet.sign(signCmd);
            //close alert programmatically
            swal.close();
            setWalletSuccess(true);
            const res = await Pact.wallet.sendSigned(cmd, network);
            console.log(res);
            //this is a small hack to get the polling header widget to work
            setLocalRes({ reqKey: res.requestKeys[0] });
            setPolling(true);
            pollingNotif(res.requestKeys[0]);
            await listen(res.requestKeys[0]);
            setPolling(false);
        } catch (e) {
            //wallet error alert
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            console.log(e);
        }
    };

    const emergencyUnlockKdaLocal = async (id, amount, rewards) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return -1;
            }
            const ct = creationTime();
            const pactCode = `(free.babena.emergencyunlock-kda (read-integer 'id) ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks"))`;
            const cmd = {
                pactCode: pactCode,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
					clist: [
					{ name: "coin.GAS", args: [] },
					{
					  name: `coin.TRANSFER`,
					  args: [babenaBank, lpAccount, Math.round(0.1 * amount, 12)+1]
					},
					{ name: "coin.GAS", args: [] },
					{
					  name: `coin.TRANSFER`,
					  args: [babenaBank, account.account, Math.round(0.9 * amount, 12)+1]
					},
					{
					  name: `free.babena.TRANSFER`,
					  args: [babenaBank, lpAccount, Math.round(rewards)+1]
					},
					{
					  name: `free.babena.TRANSFER`,
					  args: [babenaBank, account.account, Math.round(rewards)+1]
					}
				]
                },
                envData: {
                    id: parseInt(id),
                    'user-ks': account.guard
                },
                networkId: NETWORKID,
                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    10000,
                    ct,
                    600
                )
            };
            setCmd(cmd);
            let data = await Pact.fetch.local(cmd, network);
            setLocalRes(data);
            return data;
        } catch (e) {
            console.log(e);
            setLocalRes({});
            return -1;
        }
    };

    const emergencyUnlockKdaWallet = async (id, amount, rewards) => {
        try {
            const pactCode = `(free.babena.emergencyunlock-kda (read-integer 'id))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [
                    Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                    Pact.lang.mkCap(
                        'account capability',
                        'prove ownership',
                        'free.babena.ACCOUNT_GUARD',
                        [account.account])
                      ],
                sender: account.account,
                gasLimit: 10000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    id: parseInt(id),
                    'user-ks': account.guard
                },
                signingPubKey: account.guard.keys[0],
                networkId: NETWORKID
            };
            //alert to sign tx
            walletLoading();
            console.log(signCmd);
            const cmd = await Pact.wallet.sign(signCmd);
            //close alert programmatically
            swal.close();
            setWalletSuccess(true);
            const res = await Pact.wallet.sendSigned(cmd, network);
            console.log(res);
            //this is a small hack to get the polling header widget to work
            setLocalRes({ reqKey: res.requestKeys[0] });
            setPolling(true);
            pollingNotif(res.requestKeys[0]);
            await listen(res.requestKeys[0]);
            setPolling(false);
        } catch (e) {
            //wallet error alert
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            console.log(e);
        }
    };

    const unlockKdaLocal = async (id, amount, rewards) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return -1;
            }
            const ct = creationTime();
            const pactCode = `(free.babena.unlock-kda (read-integer 'id) ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks"))`;
            const cmd = {
                pactCode: pactCode,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
                    clist: [
                        { name: 'coin.GAS', args: [] },
                        {
                            name: 'coin.TRANSFER',
                            args: [babenaBank, account.account, amount]
                        },
                        {
                            name: 'free.babena.TRANSFER',
                            args: [babenaBank, account.account, rewards]
                        }
                    ]
                },
                envData: {
                    id: parseInt(id),
                    'user-ks': account.guard
                },
                networkId: NETWORKID,
                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    8000,
                    ct,
                    600
                )
            };
            setCmd(cmd);
            let data = await Pact.fetch.local(cmd, network);
            setLocalRes(data);
            return data;
        } catch (e) {
            console.log(e);
            setLocalRes({});
            return -1;
        }
    };

    const unlockKdaWallet = async (id, amount, rewards) => {
        try {
            const pactCode = `(free.babena.unlock-kda (read-integer 'id))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [
                    Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                    Pact.lang.mkCap(
                        'account capability',
                        'prove ownership',
                        'free.babena.ACCOUNT_GUARD',
                        [account.account])
                      ],
                sender: account.account,
                gasLimit: 8000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    id: parseInt(id),
                    'user-ks': account.guard
                },
                signingPubKey: account.guard.keys[0],
                networkId: NETWORKID
            };
            //alert to sign tx
            walletLoading();
            console.log(signCmd);
            const cmd = await Pact.wallet.sign(signCmd);
            //close alert programmatically
            swal.close();
            setWalletSuccess(true);
            const res = await Pact.wallet.sendSigned(cmd, network);
            console.log(res);
            //this is a small hack to get the polling header widget to work
            setLocalRes({ reqKey: res.requestKeys[0] });
            setPolling(true);
            pollingNotif(res.requestKeys[0]);
            await listen(res.requestKeys[0]);
            setPolling(false);
        } catch (e) {
            //wallet error alert
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            console.log(e);
        }
    };

  const unlockBabeLocal = async (id, amount, rewards) => {
    try {
      let privKey = signing.key;
      if (signing.method === "pk+pw") {
        const pw = await pwPrompt();
        privKey = await decryptKey(pw);
      }
      if (privKey.length !== 64) {
        return -1;
      }
      const ct = creationTime();
      const pactCode = `(free.babena.unlock-babe (read-integer 'id) ${JSON.stringify(
        account.account
      )} (read-keyset "user-ks"))`;
      const cmd = {
        pactCode: pactCode,
        keyPairs: {
          publicKey: account.guard.keys[0],
          secretKey: privKey,
          clist: [
            {
              name: `free.babena.TRANSFER`,
              args: [babenaBank, account.account, Math.round(rewards + amount, 12)+1]
            }
          ]
        },
        envData: {
          id: parseInt(id),
          "user-ks": account.guard
        },
        networkId: NETWORKID,
        meta: Pact.lang.mkMeta(
          account.account,
          chainId,
          GAS_PRICE,
          8000,
          ct,
          600
        )
      };
      setCmd(cmd);
      let data = await Pact.fetch.local(cmd, network);
      setLocalRes(data);
      return data;
    } catch (e) {
      console.log(e);
      setLocalRes({});
      return -1;
    }
  };

  const unlockBabeWallet = async (id, amount, rewards) => {
    try {
      const pactCode = `(free.babena.unlock-babe (read-integer 'id))`;
      const signCmd = {
        pactCode: pactCode,
        caps: [
          Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
          Pact.lang.mkCap(
            'account capability',
            'prove ownership',
            'free.babena.ACCOUNT_GUARD',
            [account.account])
        ],
        sender: account.account,
        gasLimit: 8000,
        gasPrice: GAS_PRICE,
        chainId: chainId,
        ttl: 600,
        envData: {
          id: parseInt(id),
          "user-ks": account.guard
        },
        signingPubKey: account.guard.keys[0],
        networkId: NETWORKID
      };
      //alert to sign tx
      walletLoading();
      console.log(signCmd);
      const cmd = await Pact.wallet.sign(signCmd);
      //close alert programmatically
      swal.close();
      setWalletSuccess(true);
      const res = await Pact.wallet.sendSigned(cmd, network);
      console.log(res);
      //this is a small hack to get the polling header widget to work
      setLocalRes({ reqKey: res.requestKeys[0] });
      setPolling(true);
      pollingNotif(res.requestKeys[0]);
      await listen(res.requestKeys[0]);
      setPolling(false);
    } catch (e) {
      //wallet error alert
      if (e.message.includes("Failed to fetch")) walletError();
      else walletSigError();
      console.log(e);
    }
  };

    const lockBabeLocal = async (amtBabe, dayBabe) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return -1;
            }
            const ct = creationTime();
            const pactCode = `(free.babena.lock-babe ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks") (read-integer 'day) (read-decimal 'amt))`;
            const cmd = {
                pactCode: pactCode,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
                    clist: [
                        { name: 'coin.GAS', args: [] },
                        {
                            name: 'free.babena.TRANSFER',
                            args: [account.account, babenaBank, reduceBalance(amtBabe, 12)]
                        }
                    ]
                },
                envData: {
                    amt: amtBabe,
                    day: dayBabe,
                    'user-ks': account.guard
                },
                networkId: NETWORKID,
                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    8000,
                    ct,
                    600
                )
            };
            setCmd(cmd);
            let data = await Pact.fetch.local(cmd, network);
            setLocalRes(data);
            return data;
        } catch (e) {
            console.log(e);
            setLocalRes({});
            return -1;
        }
    };

    const lockBabeWallet = async (amtBabe, dayBabe) => {
        try {
            const pactCode = `(free.babena.lock-babe ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks") (read-integer 'day) (read-decimal 'amt))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [
                    Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                    Pact.lang.mkCap(
                        'transfer capability',
                        'transfer token in',
                        'free.babena.TRANSFER',
                        [account.account, babenaBank, reduceBalance(amtBabe, 12)]
                    )
                ],
                sender: account.account,
                gasLimit: 8000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    amt: amtBabe,
                    day: dayBabe,
                    'user-ks': account.guard
                },
                signingPubKey: account.guard.keys[0],
                networkId: NETWORKID
            };
            //alert to sign tx
            walletLoading();
            console.log(signCmd);
            const cmd = await Pact.wallet.sign(signCmd);
            //close alert programmatically
            swal.close();
            setWalletSuccess(true);
            const res = await Pact.wallet.sendSigned(cmd, network);
            console.log(res);
            //this is a small hack to get the polling header widget to work
            setLocalRes({ reqKey: res.requestKeys[0] });
            setPolling(true);
            pollingNotif(res.requestKeys[0]);
            await listen(res.requestKeys[0]);
            setPolling(false);
        } catch (e) {
            //wallet error alert
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            console.log(e);
        }
    };

    const emergencyUnlockBabeLocal = async (id, amount, rewards) => {
        try {
            let privKey = signing.key;
            if (signing.method === 'pk+pw') {
                const pw = await pwPrompt();
                privKey = await decryptKey(pw);
            }
            if (privKey.length !== 64) {
                return -1;
            }
            const ct = creationTime();
            const pactCode = `(free.babena.emergencyunlock-babe (read-integer 'id) ${JSON.stringify(
                account.account
            )} (read-keyset "user-ks"))`;
            const cmd = {
                pactCode: pactCode,
                keyPairs: {
                    publicKey: account.guard.keys[0],
                    secretKey: privKey,
                    clist: [
                        { name: 'coin.GAS', args: [] },
                        {
                            name: 'free.babena.TRANSFER',
                            args: [babenaBank, lpAccount, Math.round(amount + rewards, 12)+1]
                        },
                        {
                            name: 'free.babena.TRANSFER',
                            args: [babenaBank, account.account, Math.round(amount + rewards, 12)+1]
                        }
                    ]
                },
                envData: {
                    id: parseInt(id),
                    'user-ks': account.guard
                },
                networkId: NETWORKID,
                meta: Pact.lang.mkMeta(
                    account.account,
                    chainId,
                    GAS_PRICE,
                    10000,
                    ct,
                    600
                )
            };
            setCmd(cmd);
            let data = await Pact.fetch.local(cmd, network);
            setLocalRes(data);
            return data;
        } catch (e) {
            console.log(e);
            setLocalRes({});
            return -1;
        }
    };

    const emergencyUnlockBabeWallet = async (id, amount, rewards) => {
        try {
            const pactCode = `(free.babena.emergencyunlock-babe (read-integer 'id))`;
            const signCmd = {
                pactCode: pactCode,
                caps: [
                    Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                    Pact.lang.mkCap(
                       'account capability',
                       'prove ownership',
                       'free.babena.ACCOUNT_GUARD',
                       [account.account])
                ],
                sender: account.account,
                gasLimit: 10000,
                gasPrice: GAS_PRICE,
                chainId: chainId,
                ttl: 600,
                envData: {
                    id: parseInt(id),
                    'user-ks': account.guard
                },
                signingPubKey: account.guard.keys[0],
                networkId: NETWORKID
            };
            //alert to sign tx
            walletLoading();
            console.log(signCmd);
            const cmd = await Pact.wallet.sign(signCmd);
            //close alert programmatically
            swal.close();
            setWalletSuccess(true);
            const res = await Pact.wallet.sendSigned(cmd, network);
            console.log(res);
            //this is a small hack to get the polling header widget to work
            setLocalRes({ reqKey: res.requestKeys[0] });
            setPolling(true);
            pollingNotif(res.requestKeys[0]);
            await listen(res.requestKeys[0]);
            setPolling(false);
        } catch (e) {
            //wallet error alert
            if (e.message.includes('Failed to fetch')) {walletError();}
            else {walletSigError();}
            console.log(e);
        }
    };

    const mkReq = function (cmd) {
        return {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(cmd)
        };
    };

    const parseRes = async function (raw) {
        const rawRes = await raw;
        const res = await rawRes;
        if (res.ok) {
            const resJSON = await rawRes.json();
            return resJSON;
        }
        const resTEXT = await rawRes.text();
        return resTEXT;

    };

    //------------------------------------------------------------------------------------------------------------------------
    //                  END KPENNY FUNCTIONS ONLY
    //------------------------------------------------------------------------------------------------------------------------

    //COMPUTE_OUT

    let computeOut = function (amountIn) {
        let reserveOut = Number(pairReserve['token1']);
        let reserveIn = Number(pairReserve['token0']);
        let numerator = Number(amountIn * (1 - FEE) * reserveOut);
        let denominator = Number(reserveIn + amountIn * (1 - FEE));
        return numerator / denominator;
    };

    //COMPUTE_IN
    let computeIn = function (amountOut) {
        let reserveOut = Number(pairReserve['token1']);
        let reserveIn = Number(pairReserve['token0']);
        let numerator = Number(reserveIn * amountOut);
        let denominator = Number((reserveOut - amountOut) * (1 - FEE));
        // round up the last digit
        return numerator / denominator;
    };

    // function computePriceImpact(amountIn, amountOut) {
    //     const reserveOut = Number(pairReserve['token1']);
    //     const reserveIn = Number(pairReserve['token0']);
    //     const midPrice = reserveOut / reserveIn;
    //     const exactQuote = amountIn * midPrice;
    //     const slippage = (exactQuote - amountOut) / exactQuote;
    //     return slippage;
    // }

    function priceImpactWithoutFee(priceImpact) {
        return priceImpact - realizedLPFee();
    }

    function realizedLPFee(numHops = 1) {
        return 1 - (1 - FEE) * numHops;
    }

    const getTotaLockedKda = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(coin.get-balance "babena-bank")',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal) {setTotaLockedKda(data.result.data.decimal);}
                else {setTotaLockedKda(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getTotaLockedBabe = async () => {
        try {
            let data = await Pact.fetch.local(
                {
                    pactCode: '(free.babena.get-balance "babena-bank")',
                    keyPairs: Pact.crypto.genKeyPair(),
                    meta: Pact.lang.mkMeta('', chainId, 0.01, 1000, 28800, creationTime())
                },
                network
            );
            if (data.result.status === 'success') {
                if (data.result.data.decimal) {setTotaLockedBabe(data.result.data.decimal);}
                else {setTotaLockedBabe(data.result.data);}
            }
        } catch (e) {
            console.log(e);
        }
    };


//========================================================================================================================  
// X - Wallet Functions
//------------------------------------------------------------------------------------------------------------------------
//===============================================================
//===============================================================

const [xAccount, setxAccount] = useState(); 
  
const isXWalletInstalled = () => {
  const { kadena } = window;
  return Boolean(kadena && kadena.isKadena);
};

const initialize = async () => {
    console.log('init')
 
let result;
if (isXWalletInstalled()) {
  const {kadena} = window;
  let res = await kadena.request({ 
    method: 'kda_connect',
    networkId:NETWORKID
  });  
  console.log('res')
  console.log(res)
   result = await kadena.request({
    method: 'kda_requestAccount',
    networkId:NETWORKID,
    //domain: window.location.hostname
  })
  console.log('result')
  console.log(result)

  if(result.status === 'success') {
      setxAccount(result.wallet)
 
  } else {
      console.log('There was an error with kda_requestAccount')
  }
}
};

useEffect(() => {
  window.addEventListener('load', initialize);
}, [])

const lockBabeXwallet = async (amtBabe, dayBabe) => {
    const {kadena} = window;  
    try {
        const pactCode = `(free.babena.lock-babe ${JSON.stringify(
            account.account
        )} (read-keyset "user-ks") (read-integer 'day) (read-decimal 'amt))`;
        const signCmd = {
            pactCode: pactCode,
            caps: [
                Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                Pact.lang.mkCap(
                    'transfer capability',
                    'transfer token in',
                    'free.babena.TRANSFER',
                    [account.account, babenaBank, reduceBalance(amtBabe, 12)]
                )
            ],
            sender: account.account,
            gasLimit: 10000,
            gasPrice: GAS_PRICE,
            chainId: chainId,
            ttl: 600,
            envData: {
                amt: amtBabe,
                day: dayBabe,
                'user-ks': account.guard
            },
            signingPubKey: account.guard.keys[0],
            networkId: NETWORKID
        };
        let result; 
        try {
       //alert to sign tx
       walletLoading();
        result = await kadena.request({
           method: "kda_requestSign",
           networkId: NETWORKID,
           data: {
               networkId: NETWORKID,
               signingCmd: signCmd
           }
       })
       
       //close alert programmatically
       swal.close();
      } catch (error) {
          console.log(error, 'there was an error')
      }
  
      try {
       const res = await Pact.wallet.sendSigned(result.signedCmd, network);
      console.log(res, 'res from Pact.wallet.sendSigned')
      pollingNotif(res.requestKeys[0]);
      await listen(res.requestKeys[0]);
   } catch (error) {
          console.log('there was an error with Pact.wallet.sendSigned')
      }
    } catch (e) {
        //wallet error alert
        if (e.message.includes('Failed to fetch')) {walletError();}
        else {walletSigError();}
        console.log(e);
    }
};

const unlockBabeXwallet = async (id, amount, rewards) => {
    const {kadena} = window; 
    try {
      const pactCode = `(free.babena.unlock-babe (read-integer 'id))`;
      const signCmd = {
        pactCode: pactCode,
        caps: [
          Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
          Pact.lang.mkCap(
            'account capability',
            'prove ownership',
            'free.babena.ACCOUNT_GUARD',
            [account.account])
        ],
        sender: account.account,
        gasLimit: 10000,
        gasPrice: GAS_PRICE,
        chainId: chainId,
        ttl: 600,
        envData: {
          id: parseInt(id),
          "user-ks": account.guard
        },
        signingPubKey: account.guard.keys[0],
        networkId: NETWORKID
      };
      let result; 
      try {
     //alert to sign tx
     walletLoading();
      result = await kadena.request({
         method: "kda_requestSign",
         networkId: NETWORKID,
         data: {
             networkId: NETWORKID,
             signingCmd: signCmd
         }
     })
     
     //close alert programmatically
     swal.close();
    } catch (error) {
        console.log(error, 'there was an error')
    }

    try {
     const res = await Pact.wallet.sendSigned(result.signedCmd, network);
    console.log(res, 'res from Pact.wallet.sendSigned')
    pollingNotif(res.requestKeys[0]);
    await listen(res.requestKeys[0]);
 } catch (error) {
        console.log('there was an error with Pact.wallet.sendSigned')
    }
  } catch (e) {
      //wallet error alert
      if (e.message.includes('Failed to fetch')) {walletError();}
      else {walletSigError();}
      console.log(e);
  }
  };

  const emergencyUnlockBabeXwallet = async (id, amount, rewards) => {
    const {kadena} = window; 
    try {
        const pactCode = `(free.babena.emergencyunlock-babe (read-integer 'id))`;
        const signCmd = {
            pactCode: pactCode,
            caps: [
                Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                Pact.lang.mkCap(
                   'account capability',
                   'prove ownership',
                   'free.babena.ACCOUNT_GUARD',
                   [account.account])
            ],
            sender: account.account,
            gasLimit: 10000,
            gasPrice: GAS_PRICE,
            chainId: chainId,
            ttl: 600,
            envData: {
                id: parseInt(id),
                'user-ks': account.guard
            },
            signingPubKey: account.guard.keys[0],
            networkId: NETWORKID
        };
        let result; 
        try {
       //alert to sign tx
       walletLoading();
        result = await kadena.request({
           method: "kda_requestSign",
           networkId: NETWORKID,
           data: {
               networkId: NETWORKID,
               signingCmd: signCmd
           }
       })
       
       //close alert programmatically
       swal.close();
      } catch (error) {
          console.log(error, 'there was an error')
      }
  
      try {
       const res = await Pact.wallet.sendSigned(result.signedCmd, network);
      console.log(res, 'res from Pact.wallet.sendSigned')
      pollingNotif(res.requestKeys[0]);
      await listen(res.requestKeys[0]);
   } catch (error) {
          console.log('there was an error with Pact.wallet.sendSigned')
      }
    } catch (e) {
        //wallet error alert
        if (e.message.includes('Failed to fetch')) {walletError();}
        else {walletSigError();}
        console.log(e);
    }
};


const emergencyUnlockKdaXwallet = async (id, amount, rewards) => {
    const {kadena} = window; 
    try {
        const pactCode = `(free.babena.emergencyunlock-kda (read-integer 'id))`;
        const signCmd = {
            pactCode: pactCode,
            caps: [
                Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                Pact.lang.mkCap(
                    'account capability',
                    'prove ownership',
                    'free.babena.ACCOUNT_GUARD',
                    [account.account])
                  ],
            sender: account.account,
            gasLimit: 10000,
            gasPrice: GAS_PRICE,
            chainId: chainId,
            ttl: 600,
            envData: {
                id: parseInt(id),
                'user-ks': account.guard
            },
            signingPubKey: account.guard.keys[0],
            networkId: NETWORKID
        };
        let result; 
        try {
       //alert to sign tx
       walletLoading();
        result = await kadena.request({
           method: "kda_requestSign",
           networkId: NETWORKID,
           data: {
               networkId: NETWORKID,
               signingCmd: signCmd
           }
       })
       
       //close alert programmatically
       swal.close();
      } catch (error) {
          console.log(error, 'there was an error')
      }
  
      try {
       const res = await Pact.wallet.sendSigned(result.signedCmd, network);
      console.log(res, 'res from Pact.wallet.sendSigned')
      pollingNotif(res.requestKeys[0]);
      await listen(res.requestKeys[0]);
   } catch (error) {
          console.log('there was an error with Pact.wallet.sendSigned')
      }
    } catch (e) {
        //wallet error alert
        if (e.message.includes('Failed to fetch')) {walletError();}
        else {walletSigError();}
        console.log(e);
    }
};

const unlockKdaXwallet = async (id, amount, rewards) => {
    const {kadena} = window; 
    try {
        const pactCode = `(free.babena.unlock-kda (read-integer 'id))`;
        const signCmd = {
            pactCode: pactCode,
            caps: [
                Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
                Pact.lang.mkCap(
                    'account capability',
                    'prove ownership',
                    'free.babena.ACCOUNT_GUARD',
                    [account.account])
                  ],
            sender: account.account,
            gasLimit: 10000,
            gasPrice: GAS_PRICE,
            chainId: chainId,
            ttl: 600,
            envData: {
                id: parseInt(id),
                'user-ks': account.guard
            },
            signingPubKey: account.guard.keys[0],
            networkId: NETWORKID
        };
        let result; 
        try {
       //alert to sign tx
       walletLoading();
        result = await kadena.request({
           method: "kda_requestSign",
           networkId: NETWORKID,
           data: {
               networkId: NETWORKID,
               signingCmd: signCmd
           }
       })
       
       //close alert programmatically
       swal.close();
      } catch (error) {
          console.log(error, 'there was an error')
      }
  
      try {
       const res = await Pact.wallet.sendSigned(result.signedCmd, network);
      console.log(res, 'res from Pact.wallet.sendSigned')
      pollingNotif(res.requestKeys[0]);
      await listen(res.requestKeys[0]);
   } catch (error) {
          console.log('there was an error with Pact.wallet.sendSigned')
      }
    } catch (e) {
        //wallet error alert
        if (e.message.includes('Failed to fetch')) {walletError();}
        else {walletSigError();}
        console.log(e);
    }
};



// ============================================================================================================================================
    
    return (
        <PactContext.Provider
            value={{
                GAS_PRICE,
                PRECISION,
                //tokens,
                account,
                verifiedAccount,
                getAccountVerification,
                updateAccount,
                updateAccountInformation,
                setVerifiedAccount,
                getTokenAccount,
                getRatio,
                getRatio1,
                supplied,
                setSupplied,
                //addLiquidityWallet,
                addLiquidityLocal,
                removeLiquidityWallet,
                removeLiquidityLocal,
                createTokenPairLocal,
                getviewMyLocksBabe,
                getviewMyLocksKda,
                pairAccount,
                pairAccountBalance,
                getPairAccount,
                getPairAccountBalance,
                privKey,
                storePrivKey,
                //tokenAccount,
                tokenFromAccount,
                tokenToAccount,
                getPair,
                getReserves,
                pairReserve,
                ratio,
                swapSend,
                slippage,
                storeSlippage,
                getCorrectBalance,
                //liquidityProviderFee,
                localRes,
                polling,
                setSigning,
                getPooledAmount,
                circulatingSupply,
                totalSupply,
                emergencyBabe,
                emergencyKda,
                share,
                poolBalance,
                pair,
                sendRes,
                clearSendRes,
                signing,
                setSigningMethod,
                encryptKey,
                signingWallet,
                walletSuccess,
                setWalletSuccess,
                registered,
                setRegistered,
                logout,
                hasWallet,
                ttl,
                setTtl,
                sigView,
                setSigView,
                pw,
                setPw,
                storeTtl,
                lockKdaLocal,
                lockKdaWallet,
                lockBabeLocal,
                lockBabeWallet,
                unlockKdaLocal,
                unlockKdaWallet,
                unlockBabeLocal,
                unlockBabeWallet,
                emergencyUnlockKdaLocal,
                emergencyUnlockKdaWallet,
                emergencyUnlockBabeLocal,
                emergencyUnlockBabeWallet,
                //kpennyRedeemWallet,
                availableKda,
                sixtyDaysKda,
                wholeYearKda,
                getAvailableKda,
                getAvailableBabe,
                availableBabe,
                babeBalance,
                sixtyDaysBabe,
                wholeYearBabe,
                getMultiplierBabe,
                getMultiplierKda,
                multiplierKda,
                multiplierBabe,
                viewMyLocksKda,
                viewMyLocksBabe,
                viewallkda,
                viewallbabe,
                getallkda,
                getallbabe,
                totaLockedKda,
                totaLockedBabe,
                // kpennyRedeemLocal,
                // kpennySpreadWallet,
                // kpennySpreadLocal,
                computeIn,
                computeOut,
                //computePriceImpact,
                priceImpactWithoutFee,
                lockBabeXwallet,
                xAccount,
                unlockBabeXwallet,
                emergencyUnlockBabeXwallet,
                emergencyUnlockKdaXwallet,
                unlockKdaXwallet
            }}
        >
            {props.children}
        </PactContext.Provider>
    );
};

export const PactConsumer = PactContext.Consumer;

export const withPactContext = (Component) => (props) => (
    <PactConsumer>
        {(providerProps) => (
            <Component {...props} sessionContextProps={providerProps} />
        )}
    </PactConsumer>
);



// const kpennyRedeemLocal = async () => {
//     try {
//         let privKey = signing.key;
//         if (signing.method === 'pk+pw') {
//             const pw = await pwPrompt();
//             privKey = await decryptKey(pw);
//         }
//         if (privKey.length !== 64) {
//             return -1;
//         }
//         const ct = creationTime();
//         const pactCode = `(free.kd5.withdraw ${JSON.stringify(
//             account.account
//         )} (read-keyset "user-ks"))`;
//         const cmd = {
//             pactCode: pactCode,
//             keyPairs: {
//                 publicKey: account.guard.keys[0],
//                 secretKey: privKey,
//                 clist: [
//                     { name: 'coin.GAS', args: [] },
//                     {
//                         name: 'coin.TRANSFER',
//                         args: ['temporary-holder', account.account, 99999]
//                     }
//                 ]
//             },
//             envData: {
//                 'user-ks': account.guard
//             },
//             networkId: NETWORKID,
//             meta: Pact.lang.mkMeta(
//                 account.account,
//                 chainId,
//                 GAS_PRICE,
//                 6000,
//                 ct,
//                 600
//             )
//         };
//         setCmd(cmd);
//         let data = await Pact.fetch.local(cmd, network);
//         setLocalRes(data);
//         return data;
//     } catch (e) {
//         console.log(e);
//         setLocalRes({});
//         return -1;
//     }
// };

// const kpennyRedeemWallet = async () => {
//     try {
//         const pactCode = `(free.kd5.withdraw ${JSON.stringify(
//             account.account
//         )} (read-keyset "user-ks"))`;
//         const signCmd = {
//             pactCode: pactCode,
//             caps: [
//                 Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
//                 Pact.lang.mkCap(
//                     'transfer capability',
//                     'transfer token in',
//                     'coin.TRANSFER',
//                     ['temporary-holder', account.account, 99999]
//                 )
//             ],
//             sender: account.account,
//             gasLimit: 3000,
//             gasPrice: GAS_PRICE,
//             chainId: chainId,
//             ttl: 600,
//             envData: {
//                 'user-ks': account.guard
//             },
//             signingPubKey: account.guard.keys[0],
//             networkId: NETWORKID
//         };
//         //alert to sign tx
//         walletLoading();
//         console.log(signCmd);
//         const cmd = await Pact.wallet.sign(signCmd);
//         //close alert programmatically
//         swal.close();
//         setWalletSuccess(true);
//         const res = await Pact.wallet.sendSigned(cmd, network);
//         console.log(res);
//         //this is a small hack to get the polling header widget to work
//         setLocalRes({ reqKey: res.requestKeys[0] });
//         setPolling(true);
//         pollingNotif(res.requestKeys[0]);
//         await listen(res.requestKeys[0]);
//         setPolling(false);
//     } catch (e) {
//         //wallet error alert
//         if (e.message.includes('Failed to fetch')) {walletError();}
//         else {walletSigError();}
//         console.log(e);
//     }
// };

// const kpennySpreadLocal = async () => {
//     try {
//         let privKey = signing.key;
//         if (signing.method === 'pk+pw') {
//             const pw = await pwPrompt();
//             privKey = await decryptKey(pw);
//         }
//         if (privKey.length !== 64) {
//             return -1;
//         }
//         const ct = creationTime();
//         const pactCode = '(free.kd5.sendpayment)';
//         const cmd = {
//             pactCode: pactCode,
//             keyPairs: {
//                 publicKey: account.guard.keys[0],
//                 secretKey: privKey,
//                 clist: [
//                     { name: 'coin.GAS', args: [] },
//                     {
//                         name: 'coin.TRANSFER',
//                         args: [asicMiner, 'temporary-holder', 99999]
//                     }
//                 ]
//             },
//             envData: {},
//             networkId: NETWORKID,
//             meta: Pact.lang.mkMeta(
//                 account.account,
//                 chainId,
//                 GAS_PRICE,
//                 30000,
//                 ct,
//                 600
//             )
//         };
//         setCmd(cmd);
//         let data = await Pact.fetch.local(cmd, network);
//         setLocalRes(data);
//         return data;
//     } catch (e) {
//         console.log(e);
//         setLocalRes({});
//         return -1;
//     }
// };

// const kpennySpreadWallet = async () => {
//     try {
//         const pactCode = '(free.kd5.sendpayment)';
//         const signCmd = {
//             pactCode: pactCode,
//             caps: [
//                 Pact.lang.mkCap('Gas capability', 'Pay gas', 'coin.GAS', []),
//                 Pact.lang.mkCap(
//                     'transfer capability',
//                     'transfer token in',
//                     'coin.TRANSFER',
//                     [asicMiner, 'temporary-holder', 99999]
//                 )
//             ],
//             sender: account.account,
//             gasLimit: 30000,
//             gasPrice: GAS_PRICE,
//             chainId: chainId,
//             ttl: 600,
//             envData: {
//                 'user-ks': account.guard
//             },
//             signingPubKey: account.guard.keys[0],
//             networkId: NETWORKID
//         };
//         //alert to sign tx
//         walletLoading();
//         console.log(signCmd);
//         const cmd = await Pact.wallet.sign(signCmd);
//         //close alert programmatically
//         swal.close();
//         setWalletSuccess(true);
//         const res = await Pact.wallet.sendSigned(cmd, network);
//         console.log(res);
//         //this is a small hack to get the polling header widget to work
//         setLocalRes({ reqKey: res.requestKeys[0] });
//         setPolling(true);
//         pollingNotif(res.requestKeys[0]);
//         await listen(res.requestKeys[0]);
//         setPolling(false);
//     } catch (e) {
//         //wallet error alert
//         if (e.message.includes('Failed to fetch')) {walletError();}
//         else {walletSigError();}
//         console.log(e);
//     }
// };
