import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
//import FormContainer from '../components/shared/FormContainer';
import { PactContext } from "../contexts/PactContext";
// import Table from "react-bootstrap/Table";
import "../styles/modals.css";
import "../App.css";
import SortableTh from "../components/SortableTh";

import { roundNumbers, formatUnixTimestamp } from "../utils/helpers";
import { orderBy } from "lodash-es";
import useInterval from "../hooks/useInterval";

import axios from "axios";
import { getPriceInKadena } from "./PactApi";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ColumnContainer = styled.div``;

const Activelockslist = () => {
  const pact = React.useContext(PactContext);

  const [kdaSortOrder, setKdaSortOrder] = useState({
    column: null,
    direction: null,
  });
  const [babeSortOrder, setBabeSortOrder] = useState({
    column: null,
    direction: null,
  });
  const [pollingTimer, setPollingTimer] = useState(null);
  const [kdaPrice, setKdaPrice] = useState();
  const [currPrice, setCurrPrice] = useState(null);
  const [babenaPrice, setBabenaPrice] = useState();
  const [totalTvl, setTotalTvl] = useState();
  const [FDMC, setFDMC] = useState();
  const [mktcap, setMktcap] = useState();

  useEffect(() => {
    if (pact.account.account !== null) {
      // pact.viewallkda();
      // pact.viewallbabe();
      setPollingTimer(180000);
    }
    if (pact.account.account === null) {
      setPollingTimer(null);
    }
  }, [pact.account]);

  useInterval(() => {
    pact.viewallkda();
    pact.viewallbabe();
  }, pollingTimer);

  useEffect(() => {
    const interval = setInterval(() => {
      availableKda = Math.round(pact.totaLockedKda).toLocaleString("en-US");
      availableBabe = Math.round(pact.totaLockedBabe).toLocaleString("en-US");
      console.log("called again");
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  let availableKda = Math.round(pact.totaLockedKda).toLocaleString("en-US");
  let availableBabe = Math.round(pact.totaLockedBabe).toLocaleString("en-US") ;

  const fetchKdaPrice = async () => {
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=kadena&vs_currencies=usd`
    );
    setKdaPrice(res.data.kadena.usd);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchKdaPrice();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchKdaPrice();
  }, []);

  const fetchBebePrice = () => {
    getPriceInKadena("free.babena").then((newPrice) => {
      setCurrPrice(newPrice);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchBebePrice();
      console.log("Fetcehd bebe again");
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchBebePrice();
  }, []);

  function price(currPrice, kdaPrice) {
    if (!currPrice || !kdaPrice) {
      return "";
    } else {
      const priceInUSD = currPrice * kdaPrice;
      //text = `$${priceInUSD.toFixed(2)} Babena USD ${currPrice.toFixed(4)} KDA/Babena`;
      setBabenaPrice(priceInUSD.toFixed(6));
    }
  }

  useEffect(() => {
    price(currPrice, kdaPrice);
  }, [currPrice, kdaPrice]);

  function calcTVL(kdaPrice, target, babenaPrice, target2) {
    let price = "";
    let res = target.split(",");
    for (let i = 0; i < res.length; i++) {
      price = price + res[i];
    }
    //let price = target.replace(/[^0-9\.,]/g, '')
    let price2 = "";
    let res2 = target2.split(",");
    for (let i = 0; i < res2.length; i++) {
      price2 = price2 + res2[i];
    }

    let totalPrice;
    if (target != "" && kdaPrice != "") {
      totalPrice = parseFloat(price) * parseFloat(kdaPrice);
    }

    let totalPrice2;
    if (target2 != "" && babenaPrice != "") {
      totalPrice2 = parseFloat(price2) * parseFloat(babenaPrice);
    }

    let combinedTotals = totalPrice + totalPrice2;

    setTotalTvl(
      "$ " + (Math.round(combinedTotals * 100) / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    );

    setFDMC(
      "$ " + (Math.round(pact.totalSupply) * (babenaPrice)).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    );

    setMktcap(
      "$ " + (Math.round(pact.circulatingSupply) * (babenaPrice)).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    );
      
    // return "$ " + (Math.round(combinedTotals * 100) / 100).toLocaleString();
  }

  useEffect(() => {
    calcTVL(kdaPrice, availableKda, babenaPrice, availableBabe);
    console.log("CALC TVL CALLED");
  }, [kdaPrice, availableKda, babenaPrice, availableBabe]);

  return (
    <Container>
      <div className="stats-box  all-locks">
        <div className="stats-header">
          <span className="big-text ">Babena Analytics</span>
        </div>
        <div className="stats-box-grid">
          {/* We can remove TVL for now until kaddex I guess? */}
          <div>
            <div
              className="stats-title"
              title="Total amount of kda/babe locked in the contract"
            >
              Coins Locked
            </div>

            <div className="stats-contenct">
              <span className="medium-text">
                <span>{availableKda}</span>
                <span> KDA</span>
              </span>
              <br />
              <span className="medium-text">
                <span>{availableBabe}</span>
                <span> BABE</span>
              </span>
              {/* getemergencykda
                  getemergencytest
                  Returns total sent to LP from emergency unstakle */}
            </div>
          </div>
          <div>
            <div className="stats-title" title="Total minted supply">
              Total Supply
            </div>
            <div className="stats-contenct">
              <span className="big-text">
                {Math.round(pact.totalSupply).toLocaleString("en-US")} BABE
              </span>
              {/* gettotalsupply
                includes preminted */}
            </div>
          </div>
          <div>
            <div
              className="stats-title"
              title="Supply existing outside of the contract"
            >
              Circulating Supply
            </div>
            <div className="stats-contenct">
              <span className="big-text">
                {Math.round(pact.circulatingSupply).toLocaleString("en-US")}{" "}
                BABE
              </span>
              {/* getcirculatingsupply
                Is without preminted */}
            </div>
          </div>
          <div>
            <div
              className="stats-title"
              title="Total sent to LP from emergency unstake"
            >
              Unlock Penalty
            </div>
            <div className="stats-contenct">
              <span className="medium-text">
                {Math.round(pact.emergencyKda).toLocaleString("en-US")} KDA
              </span>{" "}
              <br />
              <span className="medium-text">
                {Math.round(pact.emergencyBabe).toLocaleString("en-US")} BABE
              </span>
              {/* getemergencykda
                getemergencytest
                Returns total sent to LP from emergency unstakle */}
            </div>
          </div>
        </div>
      </div>

      <div className="stats-box-purple all-locks">
        <div className="stats-box-grid stats-box-grid-purple">
          {/* We can remove TVL for now until kaddex I guess? */}

          <span className="medium-text purple-grid">
            <span className="stats-title"> TVL</span>
            <span>{totalTvl}</span>
          </span>

          <span className="medium-text purple-grid">
            <span className="stats-title">BABE Price</span>
            <span>{`$ ${babenaPrice}`}</span>
          </span>

          <span className="medium-text purple-grid">
            <span className="stats-title">KDA Price</span>
            <span>{`$ ${kdaPrice}`}</span>
          </span>

          <span className="medium-text purple-grid">
            <span className="stats-title">Market cap</span>
            <span>{FDMC}</span>
          </span>

          <span className="medium-text purple-grid">
            <span className="stats-title">Circ Value</span>
            <span>{mktcap}</span>
          </span>
        </div>
      </div>

      <ColumnContainer>
        <div className="table-container-box all-locks">
          <div className="table-sort-button-grid">
            <span className="table-header">All Active KDA locks</span>
          </div>

          <table className="ui selectable inverted sortable table">
            <thead>
              <tr>
                <SortableTh
                  column="id.int"
                  sorting={kdaSortOrder}
                  setSorting={setKdaSortOrder}
                >
                  Id
                </SortableTh>
                <SortableTh
                  column="timestamp.timep"
                  sorting={kdaSortOrder}
                  setSorting={setKdaSortOrder}
                >
                  Created
                </SortableTh>
                <SortableTh
                  column="amount"
                  sorting={kdaSortOrder}
                  setSorting={setKdaSortOrder}
                >
                  Amount
                </SortableTh>
                <SortableTh
                  column="rewards"
                  sorting={kdaSortOrder}
                  setSorting={setKdaSortOrder}
                >
                  Rewards
                </SortableTh>
                <SortableTh
                  column="emergency.timep"
                  sorting={kdaSortOrder}
                  setSorting={setKdaSortOrder}
                >
                  Emergency Unlocks
                </SortableTh>
                <SortableTh
                  column="unlockat.timep"
                  sorting={kdaSortOrder}
                  setSorting={setKdaSortOrder}
                >
                  Unlock Date
                </SortableTh>
              </tr>
            </thead>
            <tbody>
              {orderBy(
                pact.getallkda,
                [kdaSortOrder.column],
                [kdaSortOrder.direction]
              ).map((coinInfo, i) => {
                const {
                  id: { int: id },
                  timestamp: { timep: timestamp },
                  amount,
                  rewards,
                  unlockat: { timep: unlockat },
                  emergency: { timep: emergency },
                } = coinInfo;

                const amount_transformed =
                  typeof amount === "number" ? amount : amount.decimal;
                const rewards_transformed =
                  typeof rewards === "number" ? rewards : rewards.decimal;

                return (
                  <tr key={i}>
                    <td>{id}</td>
                    <td>{formatUnixTimestamp(timestamp)}</td>
                    <td>
                      {roundNumbers(amount_transformed, 2).toLocaleString("en")}
                    </td>
                    <td>
                      {roundNumbers(rewards_transformed, 2).toLocaleString(
                        "en"
                      )}
                    </td>
                    <td>{formatUnixTimestamp(emergency)}</td>
                    <td>{formatUnixTimestamp(unlockat)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="table-container-box all-locks">
          <div className="table-sort-button-grid">
            <span className="table-header">All Active BABE locks</span>
          </div>
          <table className="ui selectable inverted sortable table">
            <thead>
              <tr>
                <SortableTh
                  column="id.int"
                  sorting={babeSortOrder}
                  setSorting={setBabeSortOrder}
                >
                  Id
                </SortableTh>
                <SortableTh
                  column="timestamp.timep"
                  sorting={babeSortOrder}
                  setSorting={setBabeSortOrder}
                >
                  Created
                </SortableTh>
                <SortableTh
                  column="amount"
                  sorting={babeSortOrder}
                  setSorting={setBabeSortOrder}
                >
                  Amount
                </SortableTh>
                <SortableTh
                  column="rewards"
                  sorting={babeSortOrder}
                  setSorting={setBabeSortOrder}
                >
                  Rewards
                </SortableTh>
                <SortableTh
                  column="emergency.timep"
                  sorting={babeSortOrder}
                  setSorting={setBabeSortOrder}
                >
                  Emergency Unlocks
                </SortableTh>
                <SortableTh
                  column="unlockat.timep"
                  sorting={babeSortOrder}
                  setSorting={setBabeSortOrder}
                >
                  Unlock Date
                </SortableTh>
              </tr>
            </thead>
            <tbody>
              {orderBy(
                pact.getallbabe,
                [babeSortOrder.column],
                [babeSortOrder.direction]
              ).map((coinInfo, i) => {
                const {
                  id: { int: id },
                  timestamp: { timep: timestamp },
                  amount,
                  rewards,
                  unlockat: { timep: unlockat },
                  emergency: { timep: emergency },
                } = coinInfo;

                const amount_transformed =
                  typeof amount === "number" ? amount : amount.decimal;
                const rewards_transformed =
                  typeof rewards === "number" ? rewards : rewards.decimal;
                return (
                  <tr key={i}>
                    <td>{id}</td>
                    <td>{formatUnixTimestamp(timestamp)}</td>
                    <td>
                      {roundNumbers(amount_transformed, 2).toLocaleString("en")}
                    </td>
                    <td>
                      {roundNumbers(rewards_transformed, 2).toLocaleString(
                        "en"
                      )}
                    </td>
                    <td>{formatUnixTimestamp(emergency)}</td>
                    <td>{formatUnixTimestamp(unlockat)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ColumnContainer>
    </Container>
  );
};

function Price({ priceInKadena, kdaToUsd }) {
  let text = "";
  if (kdaToUsd == null || priceInKadena == null) {
    text = "had trouble fetching price, please try again";
  } else {
    const priceInUSD = priceInKadena * kdaToUsd;
    text = `USD: $${priceInUSD.toFixed(4)} KDA: ${priceInKadena.toFixed(4)}`;
  }
  return <p>{text}</p>;
}

export default Activelockslist;
