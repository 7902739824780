import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Input from '../components/shared/Input';
import Button from '../components/shared/Button';
import { PactContext } from '../contexts/PactContext';
import pwError from '../components/alerts/pwError';
import Slider from 'react-input-slider';
import kdaLogo from '../styles/kadena-logo.svg';
import '../App.css';
import TableComponent from '../components/TableComponent';
import { roundNumbers, formatUnixTimestamp, getPrecision } from '../utils/helpers';
import moment from 'moment';

import useInterval from '../hooks/useInterval';
import SideBlock from '../components/SideBlock';

const Container = styled.div``;

const combineFilters = (arr, complete, notReady, emergency, cashOut) => {
    const combined = arr.reduce((acc, val) => {
        const {
            withdrawn,
            emergency: { timep: emergency_time },
            unlockat: { timep: unlockat },
        } = val;

        const this_moment = moment().toISOString();
        if(complete) {
            if(withdrawn) {
                acc.push(val);
                return acc;
            }
        }
        if(emergency) {
            if(this_moment > emergency_time && this_moment < unlockat && !withdrawn) {
                acc.push(val);
                return acc;
            }
        }
        if(cashOut) {
            if(this_moment > unlockat && emergency_time < this_moment && !withdrawn) {
                acc.push(val);
                return acc;
            }
        }
        if(notReady) {
            if(!withdrawn && this_moment < emergency_time && this_moment < unlockat) {
                acc.push(val);
            }
        }
        return acc;
    }, []);

    // console.log(complete, emergency, arr, combined);

    return combined;
};

const Dashboard = () => {
    const pact = React.useContext(PactContext);
    const [pollingTimer, setPollingTimer] = useState(null);
    const [kdaFilters, setKdaFilters] = useState({complete: true, emergency: true, notReady: true, cashOut: true});
    const [babeFilters, setBabeFilters] = useState({complete: true, emergency: true, notReady: true, cashOut: true});

    useEffect(() => {
        if(pact.account.account !== null) {
            pact.getviewMyLocksKda();
            pact.getviewMyLocksBabe();
            setPollingTimer(180000);
        }
        if(pact.account.account === null) {
            setPollingTimer(null);
        }
    }, [pact.account]);

    useInterval(() => {
        pact.getviewMyLocksKda();
        pact.getviewMyLocksBabe();
        pact.getMultiplierKda();
        pact.getMultiplierKda();
        if(toggleState === 1) {
            pact.getAvailableKda();
        }
        if(toggleState === 2) {
            pact.getAvailableBabe();
        }
    }, pollingTimer);

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const ProgressBar = (props) => {
        return (
            <div className="progress-bar">
                <Filler percentage={props.percentage} />
            </div>
        );
    };

    const Filler = (props) => {
        return (
            <div
                className={toggleState === 2 ? 'filler-kda' : 'filler-babe'}
                style={{ width: `${props.percentage}%` }}
            />
        );
    };

    return (
        <Container>

            <div className="main-grid">
                <div>
                    <div className="table-container-box">
                        <div className="table-sort-button-grid">
                            <span className="table-header">My KDA Locks</span>
                            <div className="checkbox-flex">
                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={kdaFilters.complete} name="complete" id="complete" onChange={(e) => setKdaFilters({...kdaFilters, complete: e.target.checked})}/>
                                    <label class="checkbox-text" for="complete" style={{color: '#fff'}}>Complete</label>
                                </div>

                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={kdaFilters.notReady} name="not_ready" id="not_ready" onChange={(e) => setKdaFilters({...kdaFilters, notReady: e.target.checked})}/>
                                    <label class="checkbox-text" for="not_ready" style={{color: '#fff'}}>Not Ready</label>
                                </div>

                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={kdaFilters.emergency} name="emergency" id="emergency" onChange={(e) => setKdaFilters({...kdaFilters, emergency: e.target.checked})}/>
                                    <label class="checkbox-text" for="emergency" style={{color: '#fff'}}>Emergency</label>
                                </div>
                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={kdaFilters.cashOut} name="cash_out" id="cash_out" onChange={(e) => setKdaFilters({...kdaFilters, cashOut: e.target.checked})}/>
                                    <label class="checkbox-text" for="cash_out" style={{color: '#fff'}}>Cash Out</label>
                                </div>
                            </div>
                        </div>

                        <TableComponent
                            coinsArray={combineFilters(pact.viewMyLocksKda, kdaFilters.complete, kdaFilters.notReady, kdaFilters.emergency, kdaFilters.cashOut)}
                            unlockLocal={pact.unlockKdaLocal}
                            unlockWallet={pact.unlockKdaWallet}
                            emergencyUnlockLocal={pact.emergencyUnlockKdaLocal}
                            emergencyUnlockWallet={pact.emergencyUnlockKdaWallet}
                            signing={pact.signing}
                            localRes={pact.localRes}
                        />
                    </div>

                    <div className="table-container-box">
                        <div className="table-sort-button-grid">
                            <span className="table-header">My BABE Locks</span>
                            <div className="checkbox-flex">
                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={babeFilters.complete} name="complete_babe" id="complete_babe" onChange={(e) => setBabeFilters({...babeFilters, complete: e.target.checked})}/>
                                    <label class="checkbox-text" for="complete_babe" style={{color: '#fff'}}>Complete</label>
                                </div>
                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={babeFilters.notReady} name="not_ready_babe" id="not_ready_babe" onChange={(e) => setBabeFilters({...babeFilters, notReady: e.target.checked})}/>
                                    <label class="checkbox-text" for="not_ready_babe" style={{color: '#fff'}}>Not Ready</label>
                                </div>
                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={babeFilters.emergency} name="emergency_babe" id="emergency_babe" onChange={(e) => setBabeFilters({...babeFilters, emergency: e.target.checked})}/>
                                    <label class="checkbox-text" for="emergency_babe" style={{color: '#fff'}}>Emergency</label>
                                </div>
                                <div className="ui checkbox" id="checkboxes">
                                    <input type="checkbox" checked={babeFilters.cashOut} name="cash_out_babe" id="cash_out_babe" onChange={(e) => setBabeFilters({...babeFilters, cashOut: e.target.checked})}/>
                                    <label class="checkbox-text" for="cash_out_babe" style={{color: '#fff'}}>Cash Out</label>
                                </div>
                            </div>
                        </div>

                        <TableComponent
                            coinsArray={combineFilters(pact.viewMyLocksBabe, babeFilters.complete, babeFilters.notReady, babeFilters.emergency, babeFilters.cashOut)}
                            unlockLocal={pact.unlockBabeLocal}
                            unlockWallet={pact.unlockBabeWallet}
                            unlockXwallet={pact.unlockBabeXwallet}
                            emergencyUnlockLocal={pact.emergencyUnlockBabeLocal}
                            emergencyUnlockWallet={pact.emergencyUnlockBabeWallet}
                            signing={pact.signing}
                            localRes={pact.localRes}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Dashboard;
