import React from 'react';
import styled from 'styled-components/macro';
import { reduceBalance } from '../../../utils/reduceBalance';
import reduceToken from '../../../utils/reduceToken';
import "../../../App.css";

const Container = styled.div`
  display: flex;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;


const AccountInfo = ({ account, balance, balanceBabe, onClick }) => {
  return (
    <>
    <div className="balance-container-babe">{balanceBabe}</div>
    <Container onClick={onClick}>
      <div className="balance-container">{balance}</div>
      <div className="account-container">{account}</div>
    </Container>
    </>
  );
};

export default AccountInfo;
