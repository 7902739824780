import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Button from "../../shared/Button";
import styled from "styled-components/macro";
import reduceToken from "../../../utils/reduceToken";
import { reduceBalance } from "../../../utils/reduceBalance";
import { PactContext } from "../../../contexts/PactContext";
import KdaModal from "../../../modals/kdaModal/KdaModal";
import {
  ROUTE_INDEX,
  ROUTE_LOCK,
  ROUTE_STATS
} from "../../../router/routes";
import AccountInfo from "../header/AccountInfo";
import "../../../App.css";
import useInterval from "../../../hooks/useInterval";

const Container = styled.div`
  position: fixed;
  top: 0;
  padding: 1.7rem 0rem;
  display: flex;
  z-index: 2;
  background: #1a1a1aeb;
  backdrop-filter: blur(5px);
  justify-content: space-between;
  min-height: ${({ theme: { header } }) => `${header.height}px`};
  @media (min-width: ${({ theme: { mediaQueries } }) =>
      mediaQueries.mobileBreakpoint}) {
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Label = styled.span`
  font-size: 13px;
  font-family: neue-bold;
  text-transform: capitalize;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Item = styled(NavLink)`
  color: white;
  font-size: 14px;
  text-decoration: none;
  text-transform: capitalize;
  &.active {
    color: var(--babe0);
  }
  &:hover {
    color: white;
    opacity: 0.7;
    cursor: pointer;
  }
  &:active {
    opacity: 0.5;
  }
`;

const HamburgerListContainer = styled.div`
  border-radius: 4px;
`;

const HamburgerItem = styled(NavLink)`
  display: flex;
  align-items: center;
  font-family: neue-regular;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.primary};
  &:hover {
    color: #000000;
    & svg {
      margin-right: 10px;
      & path {
        fill: #000000;
      }
    }
  }
  & svg {
    margin-right: 10px;
  }
`;

const Header = () => {
  const [showEthModal, setShowEthModal] = useState(false);
  const [showPactModal, setShowPactModal] = useState(false);
  const [openKdaModal, setOpenKdaModal] = useState(false);
  const history = useHistory();
  const pact = useContext(PactContext);
  const [accountBalanceInteral, setAccountBalanceInterval] = useState(null);

  useEffect(() => {
      if(pact.account?.account && accountBalanceInteral === null) {
          setAccountBalanceInterval(60000);
      }
  }, [pact.account?.account,  accountBalanceInteral]);

  useInterval(() => {
      pact.updateAccountInformation(pact.account.account);
  }, accountBalanceInteral);

  return (
    <Container className="responsive-header">
      <div>
        <LeftContainer className="responsive-header-grid">
          <div
            className="header-logo"
            onClick={() => history.push(ROUTE_INDEX)}
          >
            🍌<span className="header-logo-text">Babena</span>
          </div>
          <div className="header-link-box">
            <Item to={ROUTE_LOCK}>My locks</Item>
            <Item to={ROUTE_STATS}>Active locks</Item>
          </div>
        </LeftContainer>
      </div>
      <div>
        <RightContainer>
          <Item className="mobile-none" to="#">
            <Label
              className="mono-text chain-info"
              style={{ fontFamily: "Roboto Mono" }}
            >
              Mainnet Chain 3
            </Label>
          </Item>
          {pact?.account.account ? (
            <AccountInfo
              onClick={() => setOpenKdaModal(true)}
              account={
                pact?.account.account
                  ? `${reduceToken(pact?.account.account)}`
                  : "KDA"
              }
              balance={
                pact?.account.account
                  ? `${pact?.account.balance.toLocaleString('en-US', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })} KDA `
                  : ""
              }
              balanceBabe={
                pact?.account.account
                  ? `🍌  ${pact.babeBalance.toLocaleString('en-US', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}`
                  : ""
              }
            ></AccountInfo>
          ) : (
            <></>
          )}
          {!pact?.account.account && (
            <>
              <Item className="mobile-none" to="#">
                <Button
                  background="#fde87d"
                  color="black"
                  buttonStyle={{ padding: "10px 16px" }}
                  fontSize={14}
                  onClick={() => setOpenKdaModal(true)}
                >
                  Connect Wallet
                </Button>
              </Item>
            </>
          )}
          {pact?.account.account && (
            <Item to="#" onClick={pact.logout}>
              <i class="fas fa-sign-out-alt"></i>
            </Item>
          )}
        </RightContainer>
      </div>
      <KdaModal open={openKdaModal} onClose={() => setOpenKdaModal(false)} />
    </Container>
  );
};

export default Header;
