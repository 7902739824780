import React from 'react';

const getSortingClasses = (column, sorting) => {
    return `${sorting.column === column ? 'sorted' : ''} ${sorting.direction === 'asc' ? 'ascending' : 'descending'}`;
};

const SortableTh = (props) => {

    const { column, sorting, setSorting, children } = props;

    const handleSortingClick = () => {
        if(sorting.column === column) {
            setSorting({column, direction: sorting.direction === 'asc' ? 'desc' : 'asc'});
        } else {
            setSorting({column, direction: sorting.direction});
        }
    };

    return(
        <th 
            className={getSortingClasses(column, sorting)}
            onClick={handleSortingClick}
        >
            {children}
        </th>
    );
};

export default SortableTh;