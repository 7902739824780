import React from "react";
import styled from "styled-components/macro";
import Wrapper from "../shared/Container";
import Header from "./header/Header";
import "../../App.css";

const MainContent = styled.div`
  height: 100%;

  & > div:first-child {
    display: flex;
    justify-content: center;
  }
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <div className="header-container-master">
        <Header />
      </div>
      <MainContent>{children}</MainContent>
      <div className="main-footer-grid">
        <div className="footer-grid">
          <span className="medium-text dark-number">INFO</span>
          <a className="read-more-white" target="_blank" rel="noopener noreferrer" href="https://thanos-42.medium.com/my-kadena-mints-babena-2ad6e701bd01">Babena Explained</a>
          <a className="read-more-white" target="_blank" rel="noopener noreferrer" href="https://kadenaprojectnetwork.medium.com/how-to-stake-kda-with-babe-in-zelcore-9ba66055e74b">Zelcore Tutorial</a>
        </div>
        <div className="footer-grid">
          <span className="medium-text dark-number">SOCIALS</span>
          <a className="read-more-white" target="_blank" rel="noopener noreferrer" href="https://t.me/BabenaFinance">Telegram</a>
          <a className="read-more-white" target="_blank" rel="noopener noreferrer" href="https://twitter.com/BabenaFinance">Twitter</a>
        </div>
        <div className="footer-grid">
          <span className="medium-text dark-number">SOURCE</span>
            <a className="read-more-white" target="_blank" rel="noopener noreferrer" href="https://kadena-community.github.io/kadena-tools/docs/code-viewer.html?server=api.chainweb.com&module=free.babena&chain=0">Contract Source</a>
            <span className=" dark-number">Frontend Source</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default Layout;
