import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *, *:before, *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }

    *:focus {
      outline: none;
    }

    html {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    };

    body {
      margin: 0;
      width: 100%;
      height: 100%;
      line-height: inherit;
      overflow: auto;
      min-width: 0;
      font-family: neue-regular;
      color: ${({ theme: { colors } }) => colors.primary};
      background-color: #1A1A1A;
      background-size: cover;
      background-repeat: no-repeat;
    };

    .ui.disabled.button {
      opacity: 1 !important;
    }

`;
