import React, { useContext, useState, useEffect } from 'react';
import { Modal, Menu, Icon } from 'semantic-ui-react';
import Input from '../../components/shared/Input';
import Button from '../../components/shared/Button';
import { PactContext } from '../../contexts/PactContext';
//import theme from '../../styles/theme';
import getAccounts from '../../utils/getZelcoreAccts';
import swal from '@sweetalert/with-react';
import walletAccts from '../../components/alerts/walletAccts';
import walletError from '../../components/alerts/walletError';
import selectAcct from '../../components/alerts/selectAcct';
import '../../styles/modals.css';
import '../../App.css';
import zelcoreLogo from '../../styles/zelcore-logo.png';
import chainweaverLogo from '../../styles/chainweaver-logo.png';
import xWalletLogo from '../../styles/xwallet-logo.png';

export default function Account(props) {

    const pact = useContext(PactContext);
    const {
        account,
        getAccountVerification,
        verifiedAccount,
        updateAccount,
        signing
    } = pact;

    const [acct, setAcct] = useState('');
    const [locked, setLocked] = useState(true);
    const [method, setMethod] = useState({method: '', key: ''});
    const [pk, setPk] = useState('');
    const [pw, setPw] = useState('');
    const [pwConf, setPwConf] = useState('');
    const [temp, setTemp] = useState('');
    //const [zelAcct, setZelAcct] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(account.account && pact.hasWallet()) {
            setLocked(false);
            setAcct(account.account);
        }
    }, [account.account]);

    useEffect(() => {
        if(verifiedAccount.account) {
            setLocked(false);
        }
    }, [verifiedAccount]);

    useEffect(() => {
        setMethod(signing.method);
    }, [signing.method]);

    const is_hexadecimal = (str) => {
        const regexp = /^[0-9a-fA-F]+$/;
        return regexp.test(str);
    };

    const checkKey = (key) => {
        try {
            if (key.length !== 64) {
                return false;
            } else if (!is_hexadecimal(key)) {
                return false;
            }
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    const canSubmit = () => {
        if (method === 'sign' && verifiedAccount.account !== null) {
            return true;
        }
        if (method === 'pk' && checkKey(pk)) {
            return true;
        }
        if (method === 'pk+pw' && pw === pwConf && checkKey(pk) && pw !== ''){
            return true;
        }
        return false;
    };

    const resetValues = () => {
    // setLocked(false); unnecessary
        setPk('');
        setPw('');
        setPwConf('');
        setLocked(true);
    };

    return (
        <Modal
            onClose={() => {
                pact.setRegistered(true);
                resetValues();
                props.onClose();
            }}
            open={props.open}
        >
            <div className="content">
                <div className="account-grid">
                    <div>
                        <span className="medium-text">Connect to a wallet (Chain 3)</span>
                    </div>
                    <div>
                        <Input
                            placeholder="Enter Account (only k: addresses)"
                            error={verifiedAccount.account === null && acct !== ''}
                            containerStyle={{ marginBottom: 10 }}
                            value={acct}
                            onChange={async (e, { value }) => {
                                setAcct(value);
                                setTemp(value);
                                await getAccountVerification(value);
                            }}
                        />

                    </div>

                    <span className="medium-text">Account Details</span>

                    {verifiedAccount.account ? (
                        <>
                            <div color="purple">
                                <div className="account-details-box">
                                    <span>{JSON.stringify(verifiedAccount?.guard, null, '\t')}</span>
                                </div>
                            </div>
                        </>
                    ) : temp === '' ? (
                        <></>
                    ) : (
                        <div className="account-details-box">
                            <span className="medium-text">
                                 Your account does not exist on chain 3...<br />YOU NEED FUNDS ON CHAIN 3!!!SEND KDA TO CHAIN 3<br />YOU NEED FUNDS ON CHAIN 3!!!SEND KDA TO CHAIN 3<br />YOU NEED FUNDS ON CHAIN 3!!!SEND KDA TO CHAIN 3<br />YOU NEED FUNDS ON CHAIN 3!!!SEND KDA TO CHAIN 3
                            </span>
                        </div>
                    )}
                    <div
                        style={{
                            opacity: verifiedAccount.account ? 1 : 0.3,
                        }}
                    >
                        <div>
                            <Menu color="purple" widths={1} className="sign-button">
                                <Menu.Item
                                    name="sign"
                                    active={method === 'sign'}
                                    onClick={() => setMethod('sign')}
                                    disabled={locked}
                                >
                                    <Icon name="signup" />
                                    Activate signing
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                    <div className="account-details-box">
                        <span
                            style={{
                                color: '#e3a8ff',
                                fontSize: 16,
                            }}
                        >
                            <Icon name="warning sign" /> Note
                        </span>
                        <span>
                            Please make sure the KDA account provided is controlled by your wallet.  When submitting a transaction, You will see a preview within the wallet before signing.
                        </span>
                        <div className="account-button-box">
                            <div className="wallet-button" onClick={()=> window.open('https://zelcore.io', '_blank')}>
                                <img height={25} src={zelcoreLogo} alt="zelcore" />
                                Download Zelcore
                            </div>

                            <div className="wallet-button" onClick={()=> window.open('https://kadena-1.gitbook.io/welcome-to-gitbook/chainweaver-user-guide', '_blank')}>
                                <img height={25} src={chainweaverLogo} alt="chainweaver" />
                                Download Chainweaver
                            </div>

                            <div className="wallet-button" onClick={()=> window.open('https://xwallet.kaddex.com', '_blank')}>
                                <img height={25} src={xWalletLogo} alt="Xwallet" />
                                Download Xwallet
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider" style={{ margin: '1rem 0' }}></div>
                <div className="account-button-grid">
                    <span
                        className="sub-button"
                        onClick={() => {
                            resetValues();
                            props.onClose();
                        }}
                    >
                        Cancel
                    </span>
                    <Button
                        onClick={async () => {
                            if (method === 'pk') {await pact.storePrivKey(pk);}
                            if (method === 'pk+pw') {await pact.encryptKey(pk, pw);}
                            if (method === 'sign') {await updateAccount(verifiedAccount);}
                            resetValues();
                            props.onClose();
                        }}
                        buttonStyle={{ padding: '10px 50px' }}
                        disabled={!canSubmit()}
                    >
                        Update
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
